<template>
  <div class="LottiePlayer">
    <lottie-player
      :key="component.mode || 'normal'"
      :autoplay="component.autoplay === undefined ? true : component.autoplay"
      :loop="component.loop === undefined ? true : component.loop"
      :controls="component.controls === undefined ? true : component.controls"
      :hover="component.hover === undefined ? false : component.hover"
      :mode="component.mode || 'normal'"
      :src="component.src || component.json_data"
    />
  </div>
</template>
<script>
import '@lottiefiles/lottie-player'
export default {
  name: 'Lottie',
  props: {
    component: Object,
    form: Object,
    page: Object,
    userData: Object,
    pageMeetsRequirements: Boolean,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
